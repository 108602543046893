import { Action, createReducer, createSelector, on } from '@ngrx/store';
import { MatchInvitationState } from 'models';
import { AppState } from 'store';
import {
    closeSidebar,
    getNotificationCountSuccess,
    loadMatchInvitationsSuccess,
    loadNextNotificationsSuccess,
    loadNotificationsSuccess,
    markAsReadSuccess,
    matchInvitationChanged,
    matchInvitationCommentsChanged,
    openSidebar,
    toggleSidebar,
} from './sidebar.actions';
import { ISidebarState } from './sidebar.models';
const initialState: ISidebarState = {
    items: [],
    matchInvitations: [],
    opened: false,
    notifications: [],
    counts: {
        bellCount: 0,
        bellUnreadCount: 0,
        matchInvitationCount: 0,
    },
    bellNotificationsFilter: 'all',
};

const reducer = createReducer(
    initialState,
    on(toggleSidebar, (state) => ({ ...state, opened: !state.opened })),
    on(openSidebar, (state) => ({ ...state, opened: true })),
    on(closeSidebar, (state) => ({ ...state, opened: false })),
    on(loadMatchInvitationsSuccess, (state, { payload }) => ({ ...state, matchInvitations: payload })),
    on(loadNotificationsSuccess, (state, { values, filter }) => ({ ...state, notifications: values, bellNotificationsFilter: filter })),
    on(loadNextNotificationsSuccess, (state, { values }) => ({ ...state, notifications: [...state.notifications, ...values] })),
    on(getNotificationCountSuccess, (state, counts) => ({ ...state, counts: counts })),
    on(markAsReadSuccess, (state, counts) => ({ ...state, counts: counts })),
    on(matchInvitationCommentsChanged, (state, { id, comments }) => ({
        ...state,
        matchInvitations: state.matchInvitations.map((x) => (x.id === id ? { ...x, comments } : x)),
    })),
    on(matchInvitationChanged, (state, { payload }) => {
        if (payload.state !== MatchInvitationState.Opened) {
            return {
                ...state,
                matchInvitations: state.matchInvitations.filter((x) => x.id !== payload.id),
            };
        } else {
            const index = state.matchInvitations.findIndex((x) => x.id === payload.id);
            if (index === -1) {
                return {
                    ...state,
                    matchInvitations: [...state.matchInvitations, payload],
                };
            } else {
                return {
                    ...state,
                    matchInvitations: state.matchInvitations.map((x) => (x.id === payload.id ? payload : x)),
                };
            }
        }
    }),
);

export function sidebarReducer(state: ISidebarState = initialState, action: Action): ISidebarState {
    return reducer(state, action);
}

const selectFeature = (state: AppState) => state.sidebar;
export const selectCounts = createSelector(selectFeature, (state) => state.counts);
export const selectFilter = createSelector(selectFeature, (state) => state.bellNotificationsFilter);
export const selectUnreadCount = createSelector(selectCounts, (counts) => counts.bellUnreadCount + counts.matchInvitationCount);
export const selectNotifications = createSelector(selectFeature, (state) => state.notifications);
