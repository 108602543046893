import { Action, createReducer, on } from '@ngrx/store';
import { createUniqueId } from 'core/utils';
import { DateTime } from 'luxon';
import { AppState } from 'store';
import {
    addSetToActiveMatch,
    clearActiveMatchSuccess,
    clearMatchComment,
    clearTimers,
    createActiveMatchSet,
    createActiveMatchSuccess,
    incrementTimers,
    loadSavedMatchesCountSuccess,
    loadSavedMatchesSuccess,
    restoreActiveMatchSuccess,
    restoreSavedMatchSuccess,
    setExpectedOutcomeSuccess,
    setOngoingMatches,
    setStarted,
    switchTeamMembers,
    switchTeamRoles,
    toggleTimerPause,
    undoLastGoal,
    updateActiveMatchSet,
    updateMatchComment,
} from './matches.actions';
import { IMatchesState } from './matches.model';

const initialState: IMatchesState = {
    active: null,
    ongoing: [],
    saved: [],
    savedCount: 0,
    activeTimers: {
        fromLastGoal: 0,
        fromMatchStart: 0,
        fromSetStart: 0,
        paused: false,
    },
    editingComments: {},
};

const reducer = createReducer<IMatchesState>(
    initialState,
    on(updateActiveMatchSet, (state, { set }) => {
        set.teamA.score = set.goals.filter((x) => x.idPlayer === set.teamA.defense.id || x.idPlayer === set.teamA.offense.id).length;
        set.teamB.score = set.goals.filter((x) => x.idPlayer === set.teamB.defense.id || x.idPlayer === set.teamB.offense.id).length;
        const prev = state.active.sets.pop();
        return {
            ...state,
            active: {
                ...state.active,
                endedAt: DateTime.now().toISO({ includeOffset: true }),
                sets: [...state.active.sets, { ...prev, ...set }],
            },
        };
    }),
    on(switchTeamRoles, (state, { team }) => ({
        ...state,
        active: {
            ...state.active,
            teamA: team === 'A' || team === 'AB' ? [state.active.teamA[1], state.active.teamA[0]] : state.active.teamA,
            teamB: team === 'B' || team === 'AB' ? [state.active.teamB[1], state.active.teamB[0]] : state.active.teamB,
        },
    })),
    on(switchTeamMembers, (state, { teamA, teamB }) => {
        if (state.active?.sets?.length) {
            return { ...state };
        }
        return {
            ...state,
            active: {
                ...state.active,
                teamA,
                teamB,
            },
        };
    }),
    on(createActiveMatchSuccess, (state, { teamA, teamB, uniqueId, matchSettings, idMatchInvitation }) => ({
        ...state,
        active: {
            createdAt: DateTime.now().toISO({ includeOffset: true }),
            startedAt: DateTime.now().toISO({ includeOffset: true }),
            endedAt: DateTime.now().toISO({ includeOffset: true }),
            teamA: teamA,
            teamB: teamB,
            sets: [],
            version: 1,
            restored: false,
            uniqueId: uniqueId || createUniqueId(),
            started: false,
            elapsedSeconds: 0,
            settings: matchSettings,
            idMatchInvitation: idMatchInvitation,
        },
    })),
    on(clearActiveMatchSuccess, (state, { uniqueId }) => {
        return {
            ...state,
            active: null,
            activeTimers: {
                fromLastGoal: 0,
                fromMatchStart: 0,
                fromSetStart: 0,
                paused: false,
            },
            ongoing: state.ongoing.filter((x) => x.uniqueId !== uniqueId),
        };
    }),

    on(addSetToActiveMatch, (state, { scoreTeamA, scoreTeamB }) => ({
        ...state,
        active: {
            ...state.active,
            endedAt: DateTime.now().toISO({ includeOffset: true }),
            sets: [
                ...state.active.sets,
                {
                    teamA: { defense: state.active.teamA[0], offense: state.active.teamA[1], score: scoreTeamA },
                    teamB: { defense: state.active.teamB[0], offense: state.active.teamB[1], score: scoreTeamB },
                    createdAt: DateTime.now().toISO({ includeOffset: true }),
                    goals: [],
                    elapsedSeconds: state.activeTimers.fromSetStart,
                },
            ],
        },
    })),
    on(setOngoingMatches, (state, { matches }) => ({ ...state, ongoing: matches })),
    on(createActiveMatchSet, (state, { set }) => {
        return {
            ...state,
            active: {
                ...state.active,
                endedAt: DateTime.now().toISO({ includeOffset: true }),
                sets: [...state.active.sets, set],
            },
        };
    }),
    on(restoreActiveMatchSuccess, (state, { match, timers }) => {
        if (match?.version === 1) {
            match.restored = true;
            return { ...state, active: match, activeTimers: timers };
        }
        return state;
    }),
    on(setStarted, (state, { started }) => {
        if (!state.active) {
            return;
        }
        return { ...state, active: { ...state.active, started: started } };
    }),
    on(clearTimers, (state, { fromLastGoal, fromMatchStart, fromSetStart }) => ({
        ...state,
        activeTimers: {
            fromLastGoal: fromLastGoal ? 0 : state.activeTimers.fromLastGoal,
            fromMatchStart: fromMatchStart ? 0 : state.activeTimers.fromMatchStart,
            fromSetStart: fromSetStart ? 0 : state.activeTimers.fromSetStart,
            paused: false,
        },
    })),
    on(incrementTimers, (state) => {
        if (state.activeTimers.paused) {
            return { ...state };
        }
        return {
            ...state,
            activeTimers: {
                fromLastGoal: state.activeTimers.fromLastGoal + 1,
                fromMatchStart: state.activeTimers.fromMatchStart + 1,
                fromSetStart: state.activeTimers.fromSetStart + 1,
                paused: state.activeTimers.paused,
            },
        };
    }),
    on(toggleTimerPause, (state) => ({
        ...state,
        activeTimers: {
            ...state.activeTimers,
            paused: !state.activeTimers.paused,
        },
    })),
    on(loadSavedMatchesSuccess, (state, { values }) => ({ ...state, saved: values })),
    on(loadSavedMatchesCountSuccess, (state, { count }) => ({ ...state, savedCount: count })),
    on(restoreSavedMatchSuccess, (state, { match }) => ({ ...state, active: match.match, activeTimers: match.timer })),
    on(undoLastGoal, (state) => {
        const active = { ...state.active };
        let lastSet = active.sets.at(-1);

        if (active.sets.length > 1 && lastSet.goals.length === 0) {
            active.sets.pop();
            lastSet = active.sets.at(-1);
        }
        if (lastSet) {
            lastSet.goals.pop();
            // Recompute score
            lastSet.teamA.score = lastSet.goals.filter(
                (x) => x.idPlayer === lastSet.teamA.defense.id || x.idPlayer === lastSet.teamA.offense.id,
            ).length;
            lastSet.teamB.score = lastSet.goals.filter(
                (x) => x.idPlayer === lastSet.teamB.defense.id || x.idPlayer === lastSet.teamB.offense.id,
            ).length;
        }

        return { ...state, active: active };
    }),
    on(setExpectedOutcomeSuccess, (state, { expectedOutcome }) => ({ ...state, active: { ...state.active, expectedOutcome } })),
    on(updateMatchComment, (state, { key, comment, skipIfExists }) => {
        if (skipIfExists && state.editingComments[key]) {
            return state;
        }
        return {
            ...state,
            editingComments: {
                ...state.editingComments,
                [key]: comment,
            },
        };
    }),
    on(clearMatchComment, (state, { key }) => {
        const editingComments = { ...state.editingComments };
        delete editingComments[key];
        return { ...state, editingComments };
    }),
);

export function matchesReducer(state: IMatchesState = initialState, action: Action): IMatchesState {
    return reducer(state, action);
}

export const selectActiveMatch = (state: AppState) => state.matches.active;

export const selectTimers = (state: AppState) => state.matches.activeTimers;

export const selectSaved = (state: AppState) => state.matches.saved;

export const selectSavedCount = (state: AppState) => state.matches.savedCount;
